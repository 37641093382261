<template>
  <div class="view stats">
    <div class="card" >
      <div class="card-content" v-if="hasProfile">
        <h2 class="is-size-5 mt-1 mb-3 mt-5">Historisk data</h2>
        <ul class="is-flex is-justify-content-flex-start is-flex-wrap-wrap ">
          <li class="m-1" v-for="index in ($root.weekNumber - 1)" :key="index">
            <div class="tags has-addons">
              <span class="tag">Uge {{ index }}</span>
              <span class="tag is-success" v-if="previousPayouts[`week-${index}`]">{{ formatAmount(previousPayouts[`week-${index}`].amount) }}</span>
              <span class="tag is-danger" v-else>{{ formatAmount(0) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <BarChart />
    <Footer />
  </div>
</template>

<script>
import { FormatAmount, FindTierByPoints } from '@/helpers';
import BarChart from '@/components/BarChart';
import Footer from '@/components/Footer';
export default {
  name: 'Stats',
  components:{
    BarChart,
    Footer
  },
  data(){
    return{
      
    }
  },
  computed:{
    user(){
      return this.$store.getters.user;
    },
    profile(){
      return this.$store.getters.profile;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    previousPayouts(){
      let weekNumber = 1;
      let returnValue = {};
      const tiers = this.$store.getters.tiers;
      while(weekNumber < this.$root.weekNumber){
        const entries = this.profile.entries.filter(e => e.week === weekNumber);
        if(entries.length > 0){
          const points = entries.map(item => item.point).reduce((prev, next) => prev + next);
          const tier = FindTierByPoints(tiers, points);
          if(!returnValue[`week-${weekNumber}`]){
            returnValue[`week-${weekNumber}`] = {};
          }
          returnValue[`week-${weekNumber}`] = tier;
          
        }
        weekNumber++;
      }
      return returnValue;

    }
  },
  methods:{
    formatAmount(amount){
      return FormatAmount(amount);
    },
  }
};
</script>