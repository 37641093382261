<template>
  <div class="view login">
    <section class="section">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="box">
            <LoginForm :version="version" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import {Storage} from '@/helpers';
export default {
  name: "Default",
  components: {
    LoginForm
  },
  computed:{
    version(){
      return this.$store.getters.version;
    },
    isAuth(){
      return Storage.GetUserKey() !== null;
    }
  },
  mounted() {
    if(this.isAuth){
      this.$router.push('Overview');
    }
  }
};
</script>
