import Vue from 'vue';

class eventManager {
    constructor() {
        this.vue = new Vue;
    }
    trigger(event, data = null) {
        this.vue.$emit(event, data);
    }
    listen(event, callback) {
        this.vue.$on(event, callback);
    }
    off(event, callback) {
        this.vue.$off(event, callback);
    }
    once(event, callback) {
        this.vue.$once(event, callback);
    }
}

export default eventManager;