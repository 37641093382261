<template>
  <div class="view settings">
    <div class="card">
      <div class="card-content">
        <fieldset>
          <h3 class="is-size-5 mb-2">
            Indstillinger
          </h3>
          <div class="field is-horizontal is-flex is-justify-content-space-between is-align-items-center has-background-light py-1 px-2">
            <label>Afspil lyde</label>
            <input id="PlaySoundsSetting" type="checkbox" class="switch" v-on:change="setSetting($event, 'sound')" v-model="settings.sound">
            <label for="PlaySoundsSetting"></label>
          </div>
          <div v-if="isAdmin" class="field is-horizontal is-flex is-justify-content-space-between is-align-items-center has-background-light py-1 px-2">
            <label><font-awesome-icon size="sm" icon="lock" /> Udbetalingsdag</label>
            <div class="select">
              <select :value="userSettings.payday" v-on:change="setUserSetting($event, 'payday', 'value')">
                <option v-for="payday in paydays" :key="payday.number" :value="payday.number">{{ payday.name }}</option>
              </select>
            </div>
          </div>
          <div v-if="isAdmin" class="field is-horizontal is-flex is-justify-content-space-between is-align-items-center has-background-light py-1 px-2">
            <label><font-awesome-icon size="sm" icon="lock" /> Bonus lykkehjul</label>
            <input id="ActivateBonus" type="checkbox" class="switch" v-on:change="setUserSetting($event, 'bonus', 'bool')" :checked="userSettings.bonus">
            <label for="ActivateBonus"></label>
          </div>
          <div v-if="isAdmin && userSettings.bonus === true">
            <table class="table">
              <thead>
              <tr>
                <th></th>
                <th>Label</th>
                <th>Værdi</th>
                <th>Type</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(bonusSection, index) in bonusSections" :key="`bonus-section-${index}`">
                  <td>
                    <button class="mt-1 is-clickable delete" v-on:click="deleteBonus(index)"></button>
                  </td>
                  <td>
                    <input class="input is-small" type="text" v-model="bonusSection.label">
                  </td>
                  <td>
                    <input class="input is-small" type="text" v-model="bonusSection.value">
                  </td>
                  <td>
                    <div class="select is-small">
                      <select v-model="bonusSection.type">
                        <option value="money">Penge</option>
                        <option value="item">Gave</option>
                        <option value="extra">Ekstra tur</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="4">
                  <div class="buttons is-justify-content-end">
                    <button type="button" class="button" v-on:click="addBonus">
                      <font-awesome-icon class="mr-1" icon="add" /> Tilføj ny bonus <small class="ml-1 has-text-grey-light">({{ userSettings.bonusSections.length }} / 6)</small>
                    </button>
                    <button type="button" class="button is-success" v-on:click="saveBonuses">
                      <font-awesome-icon class="mr-1" icon="floppy-disk" /> Gem
                    </button>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </fieldset>
        <hr />
        <form ref="settingsForm">
          <fieldset v-if="isAdmin">
            <div class="field is-horizontal has-background-light py-1 px-2 mb-2">
              <h4 class="is-size-6 my-2">
                <font-awesome-icon size="sm" icon="lock" /> Opgaver
              </h4>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th></th>
                <th style="width:75%;">Label</th>
                <th>Point</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(task, index) in tasks" :key="task.id">
                <td>
                  <button class="mt-1 is-clickable delete" v-on:click="deleteTask(task)"></button>
                </td>
                <td>
                  <input class="input is-small" type="text" :value="tasks[index].label"
                         v-on:input="updateTempStore($event,'task.label', index)">
                </td>
                <td>
                  <input class="input is-small" type="number" :value="tasks[index].point"
                         v-on:input="updateTempStore($event,'task.point', index)">
                </td>

              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3">
                  <div class="buttons is-justify-content-end">
                    <button type="button" class="button is-block is-primary" v-on:click="toggleModal">
                      <font-awesome-icon icon="plus"/>
                      Tilføj
                    </button>
                    <button type="button" class="button is-success" v-on:click="saveTasks">
                      <font-awesome-icon class="mr-1" icon="floppy-disk" /> Gem
                    </button>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
            <table class="table">
              <thead>
              <tr>
                <th>Interval</th>
                <th>Udløst ugeløn</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(tier, index) in tiers" :key="tier.id">
                <td>
                  <div class="field has-addons">
                    <p class="control has-icons-left">
                      <input class="input is-small" type="text" placeholder="Fra" :value="tiers[index].tier.split('-')[0]"
                      v-on:input="debounce(() => {updateTierStore($event, index)}, 250)" :ref="`tier-${index}-0`">
                      <span class="icon is-small is-left">
                        <font-awesome-icon icon="right-from-bracket" />
                      </span>
                    </p>
                    <p class="control has-icons-right">
                      <input class="input is-small" type="text" placeholder="Til" :value="tiers[index].tier.split('-')[1]"
                             v-on:input="debounce(() => {updateTierStore($event, index)}, 250)" :ref="`tier-${index}-1`">

                      <span class="icon is-small is-right">
                        <font-awesome-icon icon="right-to-bracket" />
                      </span>
                    </p>
                  </div>
                  <!--
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input is-small" type="text" pattern="\d+-+\d+" :value="tiers[index].tier"
                             v-on:input="updateTempStore($event,'tier.tier', index)" required>
                       <span class="icon is-small is-left">
                        <font-awesome-icon icon="network-wired" />
                      </span>
                    </p>
                  </div>
                  -->
                </td>
                <td>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input is-small" type="number" :value="tiers[index].amount"
                             v-on:input="updateTempStore($event,'tier.amount', index)" required>
                      <span class="icon is-small is-left">
                        <font-awesome-icon icon="sack-dollar" />
                      </span>
                    </p>
                  </div>
                  
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3">
                  <div class="buttons is-justify-content-end">
                    <button type="button" class="button is-success" v-on:click="saveTiers">
                      <font-awesome-icon class="mr-1" icon="floppy-disk" /> Gem
                    </button>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
            <hr/>
          </fieldset>
        </form>
      </div>
    </div>
    <CreateTask :modalOpen="modalOpen" @closeModal="toggleModal"/>
    <Footer />
  </div>

</template>

<script>
import CreateTask from '@/components/CreateTask';
import Footer from '@/components/Footer';
import { ApiRequest, Status, ValidateForm, ValidateElement, GetWeekDayName, CreateDebounce } from '@/helpers';

export default {
  name: 'Settings',
  components: {
    CreateTask,
    Footer
  },
  data() {
    return {
      modalOpen: false,
      bonusSections:[],
      editedTiers: [],
      editedTasks: [],
      debounce: CreateDebounce()
    };
  },
  computed: {
    paydays(){
      const paydays = [];
      for ( let i = 0; i < 7; i++ ) {
        paydays.push({
          number: i + 1,
          name: GetWeekDayName(i)
        })
      }
      return paydays;
    },
    userSettings(){
      return this.user.settings;
    },
    isAdmin() {
      return this.profile.isAdmin;
    },
    profile() {
      return this.$store.getters.profile;
    },
    user() {
      return this.$store.getters.user;
    },
    tasks() {
      return this.$store.getters.tasks;
    },
    tiers() {
      return this.$store.getters.tiers;
    },
    settings(){
      return this.$store.getters.settings;
    }
  },
  methods: {
    saveBonuses(){
      let bonusSections = [...this.bonusSections];
      this.updateBonuses(bonusSections);
    },
    deleteBonus(index){
      if(confirm('Vil du virkelig slette denne bonus?')){
        let bonusSections = [...this.bonusSections];
        bonusSections.splice(index, 1);
        this.updateBonuses(bonusSections);
      }
    },
    addBonus(){
      let bonusSections = [...this.bonusSections];
      bonusSections.push({
        label:'',
        value:'',
        type: ''
      });
      this.updateBonuses(bonusSections);
    },
    updateBonuses(bonusSections){
      ApiRequest( {
        controller: 'USER',
        action: 'setUserSetting',
        setting: 'bonus_sections',
        value: JSON.stringify(bonusSections),
        userId: this.user.userId
      } ).then( response => response.json() ).then( ( json ) => {
        if ( json.status === Status.SUCCESS ) {
          this.bonusSections = bonusSections;
          this.$store.commit( 'setUser', json );
        }
        else {
          console.error( json );
        }
      } );
    },
    setSetting(event, setting, value = null){
      if(event !== null){
        value = event.target.checked;
      }
      this.$store.commit('setSetting', { key: setting, value: value })
    },
    setUserSetting(event, setting, type){
      let value;
      if(type === 'bool'){
        value = event.target.checked;
      } else if(type === 'value'){
        value = event.target.value;
      }
      ApiRequest( {
        controller: 'USER',
        action: 'setUserSetting',
        setting: setting,
        value: value,
        userId: this.user.userId
      } ).then( response => response.json() ).then( ( json ) => {
        if ( json.status === Status.SUCCESS ) {
          this.$store.commit( 'setUser', json );
        }
        else {
          console.error( json );
        }
      } );
    },
    isFormValid() {
      //const form = this.$refs[ 'settingsForm' ];
      return ValidateForm(this.$refs[ 'settingsForm' ]);
    },
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
    saveTasks(){
      if ( this.editedTasks.length > 0 ) {
        ApiRequest( {
          controller: 'TASK',
          action: 'updateTasks',
          tasks: this.editedTasks
        } ).then( response => response.json() ).then( ( json ) => {
          if ( json.status === Status.SUCCESS ) {
            this.editedTasks = [];
            
          }
          else {
            alert( 'error' );
          }
        } );
      }
    },
    deleteTask( task ) {
      if ( confirm( `Er du sikker på du vil slette denne opgave "${ task.label }"?` ) ) {
        ApiRequest( {
          controller: 'TASK',
          action: 'deleteTask',
          taskId: task.id
        } ).then( response => response.json() ).then( ( json ) => {
          if ( json.status === Status.SUCCESS ) {
            ApiRequest( {
              controller: 'TASK',
              action: 'getTasks',
              userId: this.user.userId
            } ).then( response => response.json() ).then( ( json ) => {
              this.$store.commit( 'setTasks', json );
            } );
          }
          else {
            console.error( json );
          }
        } );
      }
    },
    updateTierStore(event, tierIndex){
      let tier = Object.assign( {}, this.tiers[ tierIndex ] );
      let tierInput0 = this.$refs[`tier-${tierIndex}-0`];
      let tierInput1 = this.$refs[`tier-${tierIndex}-1`];
      
      if ( this.editedTiers.filter( t => parseInt( t.id ) === parseInt( tier.id ) ).length === 0 ) {
        tier.tier = `${tierInput0[0].value}-${tierInput1[0].value}`;
        this.editedTiers.push( tier );
      }
      else {
        let entry = this.editedTiers.find( t => parseInt( t.id ) === parseInt( tier.id ) );
        entry.tier = `${tierInput0[0].value}-${tierInput1[0].value}`;
      }
    },
    updateTempStore( event, key, index ) {
      const ref = event.target;
      const isValid = ValidateElement(ref);
      if(isValid){
        const keyValue = key.split( '.' );
        if ( keyValue[ 0 ] === 'tier' ) {
          let tier = Object.assign( {}, this.tiers[ index ] );

          if ( this.editedTiers.filter( t => parseInt( t.id ) === parseInt( tier.id ) ).length === 0 ) {
            tier[ keyValue[ 1 ] ] = ref.value;
            this.editedTiers.push( tier );
          }
          else {
            let entry = this.editedTiers.find( t => parseInt( t.id ) === parseInt( tier.id ) );
            entry[ keyValue[ 1 ] ] = ref.value;
          }
        }
        else if ( keyValue[ 0 ] === 'task' ) {
          let task = Object.assign( {}, this.tasks[ index ] );
          if ( this.editedTasks.filter( t => parseInt( t.id ) === parseInt( task.id ) ).length === 0 ) {
            task[ keyValue[ 1 ] ] = ref.value;
            this.editedTasks.push( task );
          }
          else {
            let entry = this.editedTasks.find( t => parseInt( t.id ) === parseInt( task.id ) );
            entry[ keyValue[ 1 ] ] = ref.value;
          }
        }
      }
    },
    saveTiers(){
      if ( this.editedTiers.length > 0 ) {
        ApiRequest( {
          controller: 'USER',
          action: 'updateTiers',
          tiers: this.editedTiers
        } ).then( response => response.json() ).then( ( json ) => {
          if ( json.status === Status.SUCCESS ) {
            this.editedTiers = [];
          }
          else {
            alert( 'error' );
          }
        } );
      }
    }
  },
  mounted() {
    this.bonusSections = [...this.userSettings.bonusSections];
  }
};
</script>