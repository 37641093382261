<template>
  <div id="app" class="container is-fullheight">
    <article class="message is-small is-success pt-2" v-if="addToHomeScreenPrompt">
      <div class="message-header">
        <button class="delete" aria-label="delete"></button>
      </div>
      <div class="message-body">
        <button v-on:click="addToHomeScreen" class="button is-success">
          Tilføj til hjemmeskærm
        </button>
      </div>
    </article>
    <Navbar />
    <ErrorMsg :error-msg="errorMsg" />
    <router-view v-if="appReady && dataReady" />
    <BonusWheel v-if="appReady && dataReady" :show="hasBonus" :profile="profile" :user="user" @endBonusSpin="endBonusSpin" />
    
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import ErrorMsg from '@/components/ErrorMsg';
import BonusWheel from '@/components/BonusWheel';
import {
  ApiRequest,
  GetCurrentPoints,
  UnregisterAndClearCaches,
  Wait,
  Storage,
  GetPaydayDates
} from '@/helpers';

export default {
  name: "App",
  components:{Navbar, ErrorMsg, BonusWheel},
  data(){
    return{
      spun: false,
      addToHomeScreenPrompt: false
    }
  },
  computed:{
    version(){
      return this.$store.getters.version;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    appReady(){
      return this.$root.appReady;
    },
    dataReady(){
      return this.$root.dataReady;
    },
    isPWA(){
      return this.$root.isPWA;
    },
    errorMsg(){
      return this.$root.errorMsg;
    },
    profile(){
      return this.$store.getters.profile;
    },
    user(){
      return this.$store.getters.user;
    },
    tiers(){
      return this.$store.getters.tiers;
    },
    hasBonus(){
      if(this.profile !== null && this.profile.entries && this.user.settings){
        const currentWeekDate = new Date();
        const lastWeekDate = new Date();
        lastWeekDate.setDate(lastWeekDate.getDate() - 7);
        currentWeekDate.setHours(1, 0, 0, 0);
        const startDayOfWeek = this.user.settings.payday;
        const currentPaydayDates = GetPaydayDates(startDayOfWeek, currentWeekDate);
        const profileAchievements = this.profile.achievements;
        if(profileAchievements.length){
          const profileBonusAchievements = profileAchievements.filter(a => a.type === 'maxPointBonus');
          profileBonusAchievements.forEach((achievement) =>{
            const achievementDate = new Date(achievement.date);
            if(achievementDate >= currentPaydayDates.prevStartDate && achievementDate < currentPaydayDates.nextStartDate  ){
              this.spun = true;
            }
          });
        }
        if(this.spun === false){
          const allTiers = this.tiers;
          let lastTier = allTiers[allTiers.length - 1];
          const lastTierRange = lastTier.tier.split('-');
          const points = GetCurrentPoints(this.profile, startDayOfWeek);
          return points >= parseInt( lastTierRange[ 1 ] );
        }
        return false;
      }
      return false;
    }
  },
  methods:{
    async endBonusSpin(sectionData) {
      await Wait(3);
      this.spun = true;
      let requests = [];
      
      let addAchievement = ApiRequest(
          {
            controller: 'PROFILE',
            action: 'addAchievement',
            profileId: Storage.GetProfileKey(),
            date: new Date(),
            type: 'maxPointBonus',
            label: `Bonus vundet ved maximum point.`,
            value: sectionData.label,
          }
      );
      requests.push( addAchievement );

      if(sectionData.type === 'money'){
        let makeDeposit = ApiRequest( {
          controller: 'PROFILE',
          action: 'makeDeposit',
          profileId: Storage.GetProfileKey(),
          deposit: parseFloat(sectionData.value)
        } );
        requests.push( makeDeposit );
      }

      Promise.all( requests.map( ( request ) => {
        return request.then( ( response ) => {
          return response.json();
        } ).then( ( data ) => {
          return data;
        } );
      } ) ).then( ( values ) => {
        let json = values[0];
        if(values.length === 2){
          json = values[1].response;
        }
        this.$store.commit('setProfile', json);
      } );
    },
    onBeforeInstallPrompt(event){
      event.preventDefault();
      console.log(event);
      this.deferredPrompt = event
      this.addToHomeScreenPrompt = true;
    },
    addToHomeScreen() {
      console.log('add', this.deferredPrompt);
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt')
          } else {
            console.log('User dismissed the A2HS prompt')
          }
          this.deferredPrompt = null
        })
      }
    }
  },
  mounted() {
    ApiRequest(
        {
          controller: 'SETTINGS',
          action: 'getVersion'
        }
    ).then( response => response.json() ).then((json) =>{
      let currentLocalVersion = this.version;
      if(currentLocalVersion !== json.version){
        UnregisterAndClearCaches().then(() =>{
          this.$store.commit('setVersion', json.version);
        });
      } else{
        if(location.hash.indexOf('_v=') > -1){
          location.replace(location.href.replace(location.hash, `#${this.$route.path}`));
        }}
      
    });
    window.addEventListener('preinstallation', this.onBeforeInstallPrompt)
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt)
  }
};
</script>

<style scoped>
.add-to-homescreen-message{
  position: fixed;
  bottom: 0;
  left: 2em;
  right: 2em;
}
</style>