<template>
  <div class="view overwatch">
    <div class="card">
      <div class="card-content">
        <h2 class="is-size-4 mt-2 mb-3">Oversigt</h2>
        <div class="tile is-parent is-flex-wrap-wrap is-12">
          <div class="tile is-4 is-child" v-for="profile in childProfiles" :key="profile.id">
            <div class="box m-1" >
              <article class="media">
                <div class="media-left profile-image-wrapper sm">
                  <figure>
                    <img class="image profile-image is-clipped is-rounded is-bordered" :src="getFallbackProfileImage(profile)" :alt="profile.name">
                  </figure>
                </div>
                <div class="content">
                  <div class="is-relative">
                    <div class="is-clickable has-text-danger delete is-absolute is-right" v-on:click="deleteProfile(profile)"></div>
                    <div class="mb-3">
                      <strong class="is-block">{{ profile.name }}</strong>
                      <hr class="my-1" />
                      <small class="has-text-weight-bold mt-1 is-text-small">Optjent</small>
                      <small class="is-flex is-text-small is-justify-content-space-between">
                        Denne uge: <span>{{ getPayoutAmount(profile, 'currentWeekTier')}}</span>
                      </small>
                      <small class="is-flex is-text-small is-justify-content-space-between">
                        Sidste uge: <span>{{ getPayoutAmount(profile, 'lastWeekTier') }}</span>
                      </small>
                      <hr class="my-1" />
                      <small class="is-flex is-text-small is-justify-content-space-between">
                        Saldo: <span>{{ formatAmount(profile.balance) }}</span>
                      </small>
                    </div>
                    <div class="buttons">
                      <button class="button is-small" v-on:click="setProfileDetail(profile, 'details')">
                        <span class="icon is-small">
                          <font-awesome-icon icon="eye" />
                        </span>
                        <span>Detaljer</span>
                      </button>
                      <button class="button is-small" v-on:click="setProfileDetail(profile, 'payout')">
                        <span class="icon is-small">
                          <font-awesome-icon icon="coins" />
                        </span>
                        <span>Udbetal</span>
                      </button>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <ProfileDetails v-if="profileDetail" :profile="profileDetail" :view="profileDetailView" @closeProfileDetails="setProfileDetail(null)" />
  </div>
</template>

<script>
import {
  ApiRequest,
  GetFallbackProfileImage,
  FormatAmount,
  Storage,
  GetPayoutsForProfile
} from '@/helpers';
import Footer from '@/components/Footer';
import ProfileDetails from '@/components/ProfileDetails';
export default {
  name: 'Overwatch',
  components:{Footer, ProfileDetails},
  data(){
    return{
      profiles:[],
      profileDetail: null,
      profileDetailView: ''
    }
  },
  computed:{
    user(){
      return  this.$store.getters.user;
    },
    childProfiles(){
      return this.profiles;
    }
  },
  methods:{
    formatAmount(amount){
      return FormatAmount(amount);
    },
    setProfileDetail(profile, view){
      this.profileDetail = profile;
      this.profileDetailView = view;
      if(profile === null){
        this.getProfiles();
      }
    },
    deleteProfile(profile){
      if ( confirm( `Er du sikker på du vil slette denne profile "${ profile.name }"?` ) ) {
        ApiRequest({
          controller: 'PROFILE',
          action: 'deleteProfile',
          profileId: profile.id
        }).then( response => response.json() ).then(() =>{
          const userKey = Storage.GetUserKey();
          ApiRequest({
            controller: 'USER',
            action: 'getUserProfilesById',
            userId: userKey
          }).then( response => response.json() ).then((json) =>{
            this.profiles = json;
          });
        });
      }
    },
    getPayoutAmount(profile, payout){
      const startDayOfWeek = this.user.settings.payday;
      const tiers = this.$store.getters.tiers;
      const payouts = GetPayoutsForProfile(profile, startDayOfWeek, tiers);
      const amount = payouts[payout].amount === undefined ? 0 : payouts[payout].amount;
      return FormatAmount(amount);
    },
    hasProfileImage(profile){
      return profile.image !== '';
    },
    getFallbackProfileImage(profile){
      if(this.hasProfileImage(profile)){
        return profile.image;
      }
      return GetFallbackProfileImage(profile);
    },
    getProfiles(){
      const userKey = Storage.GetUserKey();
      ApiRequest({
        controller: 'USER',
        action: 'getUserProfilesById',
        userId: userKey
      }).then( response => response.json() ).then((json) =>{
        this.profiles = json;
      });
    }
  },
  mounted() {
    this.getProfiles();
  }
};
</script>