<template>
  <footer class="is-sticky-bottom" v-if="showFooter">
    <router-link :to="{ name: 'Tasks'}" v-if="showTaskButton">
      <font-awesome-icon icon="list-check"  /> Lav opgave
    </router-link>
    <router-link to="/" v-if="showReturnButton">
      <font-awesome-icon icon="arrow-left"  /> Tilbage
    </router-link>
    <a v-if="showSaveButton" v-on:click="save">
      <font-awesome-icon icon="save"  /> Gem
    </a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed:{
    showTaskButton(){
      return this.$route.meta.buttons.indexOf('task') > -1 && this.$store.getters.hasProfile;
    },
    showReturnButton(){
      return this.$route.meta.buttons.indexOf('return') > -1;
    },
    showSaveButton(){
      return this.$route.meta.buttons.indexOf('save') > -1;
    },
    showFooter(){
      return this.showSaveButton || this.showReturnButton || this.showTaskButton;
    }
  },
  methods:{
    save(){
      window.eventManager.trigger('save');
    }
  },
  watch:{
    $route(){
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>

</style>