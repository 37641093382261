<template>
  <div class="view tasks">
    <div class="card" >
      <div class="card-content p-1">
        <div v-if="hasProfile">
          <nav class="panel">
            <p class="panel-heading">
              Opgaver
            </p>
            <div class="panel-block">
              <p class="control has-icons-left">
                <input class="input" type="text" v-model="query" placeholder="Søg">
                <span class="search-input-clear" v-if="query.length > 0" v-on:click="query = ''">
                  <font-awesome-icon icon="xmark" />
                </span>
                <span class="icon is-left">
                  <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                </span>
              </p>
            </div>
            <a class="panel-block p-0" v-for="task in filteredTasks" :key="'task-'+task.id" v-on:change="setTask($event, task.id)">
              <div class="box-checkbox">
                <input type="checkbox" :checked="isTaskDoneToday(task.id)" :id="`task-checkbox-${task.id}`">
                <label class="p-5 is-block is-flex-grow-1 is-relative" :for="`task-checkbox-${task.id}`">
                <span class="is-flex is-justify-content-space-between">
                  <span class="is-text-medium">{{ task.label }}</span> <span class="is-text-small">+{{ task.point }}</span>
                </span>
                  <span class="sparkle-element"></span>
                </label>
              </div>
            </a>
          </nav>
        </div>
      </div>
    </div>
    <Footer  />
  </div>
</template>


<script>
import { ApiRequest, SiteUrl, Status, Storage } from '@/helpers';
import Footer from '@/components/Footer';
import party from "party-js";

export default {
  name: "Tasks",
  components:{Footer},
  data(){
    return{
      profiles: [],
      query: '',
      facets:[],
      kachingSound: new Audio( `${SiteUrl}sounds/kaching.mp3`)
    }
  },
  computed:{
    hasFacets(){
      return this.facets.length > 0
    },
    user(){
      return this.$store.getters.user;
    },
    profile(){
      return this.$store.getters.profile;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    tasks(){
      return this.$store.getters.tasks;
    },
    settings(){
      return this.$store.getters.settings;
    },
    filteredTasks(){
      let result = this.tasks;
      if(this.query !== ''){
        result = result.filter(t => t.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
      }
      return result;
    }
  },
  methods: {
    setProfile(profile){
      this.$store.commit('setProfile', profile);
    },
    setTask(event, taskId){
      const sparkleElement = event.target.parentElement.querySelector('.sparkle-element');
      if(event.target.checked){
        party.sparkles(sparkleElement, {
          count: party.variation.range(10, 60),
          speed: party.variation.range(50, 300),
        });
        if(Object.hasOwn(this.settings, 'sound') && this.settings.sound === true){
          this.kachingSound.play();
        }
        ApiRequest( {
          controller: 'TASK',
          action: 'setTaskOnProfile',
          profileId: this.profile.id,
          userId: this.user.userId,
          taskId: taskId
        } ).then(() =>{
          this.getProfileById();
        });
        
        
      } else{
        ApiRequest( {
          controller: 'TASK',
          action: 'removeTaskOnProfile',
          profileId: this.profile.id,
          userId: this.user.userId,
          taskId: taskId
        } ).then(() =>{
          this.getProfileById();
        });
      }
    },
    isTaskDoneToday(taskId){
      var entry = this.profile.entries.find(t => t.taskId === taskId);
      if(entry){
        return entry.isToday;
      }
      return false;
    },
    getProfileById(){
      ApiRequest( {
        controller: 'PROFILE',
        action: 'getProfileById',
        profileId: this.profile.id
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$store.commit('setProfile', json);
        } else{
          console.error( json );
        }
      });
    }
  },
  mounted(){
    if(Storage.GetProfileKey() === null){
      ApiRequest({
        controller: 'USER',
        action: 'getUserProfilesById',
        userId: this.user.userId
      }).then( response => response.json() ).then((json) =>{
        this.profiles = json;
      });
    } else{
      this.getProfileById();
    }
  }
};
</script>
