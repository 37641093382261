<template>
  <div :class="`modal ${show ? 'is-active' : ''}`">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="content">
          <h2 class="has-text-white has-text-centered">MAIXMUM POINT</h2>
          <h4 class="has-text-white has-text-centered">Drej på hjulet og vind din bonus</h4>
        </div>
        <div class="wheel-of-fortune">
          <canvas class="wheel" ref="wheel" width="300" height="300"></canvas>
          <div class="spin" ref="spin" v-on:click="spinEvent">{{ spinText }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import {RandomNumber} from '@/helpers';
import party from "party-js";
export default {
  name: 'BonusWheel',
  props:['show', 'profile', 'user'],
  data(){
    return{
      spinText: 'DREJ',
      friction: 0.991,
      angVelMin: 0.002,
      angVelMax: 0,
      angVel: 0,
      angle: 0,
      isSpinning: false,
      isAccelerating: false,
      animFrame: null
    }
  },
  computed:{
    sectors(){
      let returnValue = [];
      const sectionData = this.user.settings.bonusSections;
      sectionData.forEach((section, index) =>{
        returnValue.push({
          label: section.label,
          color: section.type === 'extra' ? '#F5C21B' : (index % 2 === 0) ? "#663399" : "#993366",
          index: index
        });
      });
      
      return returnValue;
    },
    PI(){
      return Math.PI;
    },
    TAU(){
      return 2 * this.PI;
    },
    arc(){
      return this.TAU / this.totalSectors;
    },
    totalSectors(){
      return this.sectors.length;
    },
    context(){
      return this.$refs.wheel.getContext('2d');
    },
    
    dia(){
      return this.context.canvas.width;
    },
    rad(){
      return this.dia / 2;
    }
  },
  methods:{
    getIndex(){
      return Math.floor(this.totalSectors - this.angle / this.TAU * this.totalSectors) % this.totalSectors;
    },
    drawSector(sector, index){
      const ang = this.arc * index;
      this.context.save();
      // COLOR
      this.context.beginPath();

      this.context.fillStyle = sector.color;
      
      this.context.moveTo(this.rad, this.rad);
      this.context.arc(this.rad, this.rad, this.rad, ang, ang + this.arc);
      this.context.lineTo(this.rad, this.rad);
      this.context.fill();
      // TEXT
      this.context.translate(this.rad, this.rad);
      this.context.rotate(ang + this.arc / 2);
      this.context.textAlign = "right";
      this.context.fillStyle = "#fff";
      this.context.font = "bold 16px sans-serif";
      this.context.fillText(sector.label, this.rad - 10, 10);
      this.context.restore();
    },
    rotate(){
      const sector = this.sectors[this.getIndex()];
      const spinElement = this.$refs.spin;
      this.context.canvas.style.transform = `rotate(${this.angle - this.PI / 2}rad)`;
      spinElement.textContent = !this.angVel ? this.spinText : sector.label;
      spinElement.style.background = sector.color;
    },
    frame(){
      if (!this.isSpinning) {
        return;
      }

      if (this.angVel >= this.angVelMax) {
        this.isAccelerating = false
      }

      // Accelerate
      if (this.isAccelerating) {
        this.angVel ||= this.angVelMin; // Initial velocity kick
        this.angVel *= 1.06; // Accelerate
      }

      // Decelerate
      else {
        this.isAccelerating = false;
        this.angVel *= this.friction; // Decelerate by friction  

        // SPIN END:
        if (this.angVel < this.angVelMin) {
          this.isSpinning = false;
          this.angVel = 0;
          cancelAnimationFrame(this.animFrame);
          const sector = this.sectors[this.getIndex()];
          this.spinText = sector.label;
          const sectionData = this.user.settings.bonusSections[sector.index];
          if(sectionData.type !== 'extra'){
            party.confetti(this.$refs.spin, {
              count: party.variation.range(99, 200),
              size: party.variation.range(0.6, 1.4),
            });

            this.$emit('endBonusSpin', sectionData);
          } else{
            this.spinText = 'DREJ';
            this.$refs.spin.style.pointerEvents = 'all';
          }
          
        }
      }

      this.angle += this.angVel; // Update angle
      this.angle %= this.TAU;    // Normalize angle
      this.rotate();      // CSS rotate!
    },
    engine(){
      this.frame();
      this.animFrame = requestAnimationFrame(this.engine);
    },
    spinEvent(){
      if (this.isSpinning) {
        return;
      }
      this.$refs.spin.style.pointerEvents = 'none';
      this.isSpinning = true;
      this.isAccelerating = true;
      this.angVelMax = RandomNumber(0.1, 0.50);
      this.engine(); // Start engine!
    }
  },
  mounted() {
    if(this.user.settings && this.user.settings.bonusSections) {
      this.sectors.forEach( this.drawSector );
      this.angle = RandomNumber(0, 5);
      this.rotate(); // Initial rotation
    }
  }
};
</script>

<style scoped lang="scss">
.wheel-of-fortune {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 300px;
  display: block;
  .wheel {
    display: block;
  }
  .spin {
    font-size: 1.2em;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 30%;
    margin: -15%;
    background: #fff;
    color: #fff;
    box-shadow: 0 0 0 8px currentColor, 0 0 15px 5px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: 0.8s;
    &::after{
      content: "";
      position: absolute;
      top: -17px;
      border: 10px solid transparent;
      border-bottom-color: currentColor;
      border-top: none;
    }
  }
}

</style>