import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    user: {},
    profile:{},
    tasks:[],
    version: '',
    settings:{}
  },
  getters:{
    version: () =>{
      return localStorage.getItem('_kaching.version');
    },
    settings: (state) =>{
      const localSettings = localStorage.getItem(`_kaching.settings.${state.profile.id}`);
      if(localSettings !== null){
        return JSON.parse(localSettings);
      }
      return {};
    },
    user: (state) => {
      return state.user;
    },
    profile: (state) =>{
      return state.profile;
    },
    isAuth: (state) =>{
      return Object.keys(state.user).length > 0;
    },
    hasProfile: (state) =>{
      return Object.keys(state.profile).length > 0;
    },
    isAdmin: (state) =>{
      return state.profile.type === true;
    },
    task: (state, taskId) =>{
      return state.tasks.find(t => t.id === taskId);
    },
    tasks: (state) =>{
      return state.tasks;
    },
    tiers: (state) =>{
      return state.user.tiers
    }
  },
  mutations: {
    setVersion(state, payload){
      state.version = payload;
      localStorage.setItem('_kaching.version', state.version);
      setTimeout(() =>{
        location.href = `${location.href}?_v=${payload}`;
        location.reload();
      }, 100);
    },
    setSetting(state, payload){
      let settings = state.settings;
      settings[payload.key] = payload.value;
      state.settings = settings;
      localStorage.setItem(`_kaching.settings.${state.profile.id}`, JSON.stringify(settings));
    },
    setUser(state, payload){
      state.user = payload.response;
      localStorage.setItem('_kaching.user', payload.response.userId);
    },
    setProfile(state, payload){
      state.profile = payload;
      sessionStorage.setItem('_kaching.profile', payload.id);
    },
    unsetProfile(state){
      state.profile = {};
      sessionStorage.removeItem('_kaching.profile');
    },
    logout(state){
      state.user = {};
      state.profile = {};
      localStorage.removeItem('_kaching.user');
      sessionStorage.removeItem('_kaching.profile');
    },
    setTasks(state, payload){
      state.tasks = [];
      state.tasks = [...payload]
    }
  },
  actions: {},
});
