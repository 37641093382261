<template>
  <form ref="createProfileForm">
    <div class="field">
      <label class="label">Brugernavn</label>
      <div class="control has-icons-left">
        <input class="input" type="text" placeholder="Navn" v-model="createdProfile.name" required v-on:input="isElementValid">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="user" />
        </span>
      </div>
    </div>
    <div class="field">
      <label class="label">Pinkode</label>
      <div class="control has-icons-left">
        <input class="input" type="text" placeholder="Pinkode" minlength="4" maxlength="4" pattern="[0-9]+" v-model="createdProfile.pin" required v-on:input="isElementValid">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="key" />
        </span>
      </div>
    </div>
    <hr />
    <div class="field" v-if="profile.isAdmin">
      <input id="isAdminInput" type="checkbox" class="switch" v-model="createdProfile.isAdmin">
      <label for="isAdminInput">Forældre profil</label>
    </div>
    
    <hr />
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-success" v-on:click.prevent="createProfile">Opret profil</button>
      </div>
      <div class="control">
        <router-link class="button is-light" :to="{ name: 'Overview'}">
          Annuller
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import { ApiRequest, ValidateElement, ValidateForm, Status } from '@/helpers';

export default {
  name: 'CreateProfileForm',
  data(){
    return{
      createdProfile: {
        name:'',
        pin:'',
        image:'',
        isAdmin: false
      }
    }
  },
  computed:{
    profile(){
      return this.$store.getters.profile;
    }
  },
  methods:{
    isElementValid(event){
      ValidateElement(event.target);
    },
    isFormValid(){
      const ref = this.$refs['createProfileForm'];
      return ValidateForm(ref);
    },
    createProfile(){
      if(!this.isFormValid()){
        return false;
      }
      const objFetchData = {
        controller: 'PROFILE',
        action: 'createProfile',
        name: this.createdProfile.name,
        pin: this.createdProfile.pin,
        image: this.createdProfile.image,
        userId: this.$store.getters.user.userId,
        isAdmin: this.createdProfile.isAdmin.toString()
      };
      ApiRequest(objFetchData).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$router.push('/overview');
        } else{
          this.$root.errorMsg = json.statusMsg;
        }
        
      });
    },  
  }
};
</script>