<template>
  <div class="tiers-container mb-4 has-text-white">
    <div :class="`tier tile ${ isCurrentTier(tier.tier) ? 'has-background-light has-text-dark' : isLastTier(tier) ? 'has-background-success has-text-light' : 'has-text-black'}`" v-for="(tier, index) in tiers" :key="`tier-${tier.id}`">
      <div class="is-flex is-flex-direction-column">
        <p :class="isCurrentTier(tier.tier) ? 'has-text-weight-bold has-text-centered is-size-5 mb-1' : 'has-text-centered is-size-5 mb-1'">
          <font-awesome-icon icon="check" v-if="currentPoints > getMaxPointByTier(tier.tier) && !isLastTier(tier)" />
          <font-awesome-icon :pulse="isLastTier(tier)" :beat="isCurrentTier(tier.tier)" icon="star" v-if="isCurrentTier(tier.tier) || isLastTier(tier)" />
          Pulje {{ index + 1 }}</p>
      </div>
      <progress v-if="!isLastTier(tier)" :class="`progress mb-1 ${currentPoints >= getMaxPointByTier(tier.tier) ? 'is-success' : isCurrentTier(tier.tier) ? 'is-success' : ''}`" :value="isCurrentTier(tier.tier, true) ? getProgress(currentPoints, tier.tier ) : 0" max="100">{{ currentPoints }}</progress>
      <div v-if="!isLastTier(tier)" class="progress-labels is-flex is-justify-content-space-between is-size-7">
        <div>{{ tier.tier.split('-')[0] }}</div>
        <div>{{ tier.tier.split('-')[1] }}</div>
      </div>
      <div class="progress-labels is-flex is-justify-content-space-between is-size-7" v-else>
        <p class="is-block has-text-centered is-flex-grow-1 has-text-weight-bold">
          MAXIMUM POINT
        </p>
      </div>
      <div class="is-flex is-flex-direction-column">
        <p :class="isCurrentTier(tier.tier) || isLastTier(tier) ? 'has-text-weight-bold has-text-centered' : 'has-text-centered'">{{ formatAmount(tier) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { FormatAmount, GetCurrentPoints } from '@/helpers';
export default {
  name: 'Tiers',
  computed:{
    tiers(){
      return this.$store.getters.tiers;
    },
    profile(){
      return this.$store.getters.profile;
    },
    user(){
      return this.$store.getters.user;
    },
    currentPoints(){
      return GetCurrentPoints(this.profile, this.user.settings.payday);
    },
    maxPoints(){
      let _temp = [];
      let tierRanges = this.tiers.map((t) =>{
        return t.tier;
      })
      tierRanges.forEach((tr) =>{
        const splitTierRange = tr.split('-');
        _temp.push(parseInt(splitTierRange[0]));
        _temp.push(parseInt(splitTierRange[1]));
      });
      return Math.max.apply(null, _temp);
    }
  },
  methods:{
    formatAmount(tier){
      return FormatAmount(tier.amount);
    },
    getProgress(amount, tier){
      const splitTierRange = tier.split('-');
      const max = parseInt(splitTierRange[1]);
      const min = parseInt(splitTierRange[0]);
      const calculatedProgress = ((amount - min) / (max - min)) * 100;
      
      return calculatedProgress === 0 ? 1 : calculatedProgress > 100 ? 100 : calculatedProgress;
      
    },
    isLastTier(tier){
      const allTiers = this.tiers;
      let lastTier = allTiers[allTiers.length - 1];
      const lastTierRange = lastTier.tier.split('-');
      return lastTier.id === tier.id && this.currentPoints >= parseInt( lastTierRange[ 1 ] );
    },
    isCurrentTier(tier, orBefore){
      const splitTierRange = tier.split('-');
      if(orBefore){
        return this.currentPoints >= parseInt( splitTierRange[ 0 ]  );
      } else{
        return this.currentPoints >= parseInt( splitTierRange[ 0 ] ) && this.currentPoints <= parseInt( splitTierRange[ 1 ] );
      }
    },
    getMaxPointByTier(tier){
      let _temp = [];
      const splitTierRange = tier.split('-');
      _temp.push(parseInt(splitTierRange[0]));
      _temp.push(parseInt(splitTierRange[1]));
      return Math.max.apply(null, _temp);
    }
  }
};
</script>