<template>
  <div class="view create-profile">
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="box">
            <CreateProfileForm />
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import CreateProfileForm from '@/components/CreateProfileForm';
import Footer from '@/components/Footer';
export default {
  name: 'CreateProfile',
  components:{
    CreateProfileForm,
    Footer
  }
};
</script>
