<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="img-container">
          <img ref="image" :src="src" crossorigin>
        </div>
        <div class="has-background-light p-2 cropper-toolbar is-flex">
          <button class="button is-success" type="button" v-on:click="save">
            <font-awesome-icon icon="save" class="mr-1"  /> Gem billede
          </button>
          <button class="button ml-auto has-background-white" type="button" v-on:click="rotate">
            <font-awesome-icon icon="rotate-right" class="mr-1" /> Rotér
          </button>
        </div>
      </div>
    
    </div>
</template>

<script>
import Cropper from "cropperjs";
export default {
  name: "ImageCropper",
  data() {
    return {
      cropper: {},
      destination: {},
      image: {}
    }
  },
  props: {
    src: String
  },
  methods:{
    save(){
      const image = this.cropper.getCroppedCanvas({width: 200, height: 200}).toDataURL('image/jpeg');
      this.$emit('imageCropped', image);
    },
    rotate(){
      this.cropper.rotate(90);
    }
  },
  mounted() {
    this.image = this.$refs.image;
    this.cropper = new Cropper(this.image, {
      movable: false,
      zoomable: false,
      rotatable: true,
      scalable: true,
      responsive:true,
      aspectRatio: 1
    })
  }
}
</script>

<style scoped>

.modal-content {
  margin: 20px auto;
  max-width: 640px;
}

img {
  width: 100%;
}
</style>