<template>
  <div class="view overview">
    <div class="card" >
      <div class="card-content" v-if="hasProfile">
        <h2 class="is-size-4 mt-2 mb-3">Optjent i denne uge</h2>
        <ul class="is-flex is-justify-content-space-between mb-2">
          <li v-for="(weekday, index) in weekdays" :key="`weekday-${index}`">
            <span :class="`is-capitalized is-size-8 ${ weekday.isPayday ? 'shiny has-text-weight-bold ' : ''} ${weekday.isToday ? 'has-text-weight-bold is-underlined has-text-black' : 'has-text-grey'}`">
              <span :class="`${weekday.isPayday ? 'inner-shiny' : ''}`">
                {{ weekday.name }}
              </span>
            </span>
          </li>
        </ul>
        <Tiers />
        <hr />
        
        <div class="is-12">
          <h2 class="is-size-4 mt-1 mb-3">I dag:</h2>
          <ul v-if="todayTasks.length > 0">
            <li v-for="task in todayTasks" :key="task.id"><font-awesome-icon icon="check" class="has-text-success"  /> {{ task.label }}</li>
          </ul>
          <p class="" v-else>Du har ikke lavet noget endnu...</p>
        </div>
        <hr />
        <div class="is-12">
          <h2 class="is-size-4 mt-1 mb-3 has-text-centered ">Optjent i sidste uge</h2>
          <div class="has-text-centered">
            <p class="has-text-weight-bold is-size-2 shiny">
              {{ lastWeekPayout }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-content" v-else>
        <h3 class="is-size-3 m-1">Profiler</h3>
        <div class="tile is-12 is-parent is-flex is-flex-wrap-wrap p-0">
          <div class="profile-image-wrapper " v-for="profile in profiles" :key="profile.id">
            <figure  class="profile-image is-relative" v-on:click="requirePin(profile.id)">
              <img class="image profile-image is-clipped is-rounded is-bordered" :src="getProfileImage(profile)" :alt="profile.name">
              <span class="profile-type-icon">
              <font-awesome-icon size="sm" v-if="!profile.isAdmin" icon="user" />
              <font-awesome-icon size="sm" v-if="profile.isAdmin" icon="user-shield" />
            </span>
            </figure>
            
            <p class="has-text-centered">
              {{ profile.name }}
            </p>
          </div>
        </div>
        <div class="tile is-12 is-parent mt-4">
          <div class="tile is-12 has-text-centered is-clickable is-child">
            <router-link class="button is-primary" :to="{ name: 'CreateProfile' }">
              <font-awesome-icon icon="user-plus" class="mr-1"  /> Opret ny profil
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pincode :request-pin="requestPin" @hasPincode="setPin" />
    <Footer  />
  </div>
</template>


<script>

import Tiers from '@/components/Tiers';
import Pincode from '@/components/Pincode';
import Footer from '@/components/Footer';

import {
  ApiRequest,
  Status,
  Storage,
  FormatAmount,
  GetFallbackProfileImage,
  GetPayoutsForProfile
} from '@/helpers';

export default {
  name: "Overview",
  components:{
    Tiers,
    Pincode,
    Footer
  },
  data(){
    return{
      profiles: [],
      requestPin: false,
      requestProfileId: '',
      pincode: ''
    }
  },
  computed:{
    weekdays(){
      var baseDate = new Date(Date.UTC(2017, 0, 2));
      var weekDays = [];
      var today = new Date();
      for(let i = 0; i < 7; i++)
      {
        weekDays.push({
          name: baseDate.toLocaleDateString('da-DK', { weekday: 'long' }),
          isToday: today.toLocaleDateString('da-DK', { weekday: 'long' }) === baseDate.toLocaleDateString('da-DK', { weekday: 'long' }),
          isPayday: this.user.settings.payday === (i + 1)
        });
        baseDate.setDate(baseDate.getDate() + 1);
      }
      return weekDays;
    },
    user(){
      return this.$store.getters.user;
    },
    profile(){
      return this.$store.getters.profile;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    tasks(){
      return this.$store.getters.tasks
    },
    todayTasks(){
      let returnValue = [];
      if(!this.hasProfile){
        return returnValue;
      }
      this.profile.entries.forEach((entry) =>{
        const task = this.tasks.find(t => t.id === entry.taskId && entry.isToday);
        if(task){
          returnValue.push(task);
        }
      });
      return returnValue;
    },
    lastWeekPayout(){
      const weekData = this.previousPayouts.lastWeekTier;
      if(Object.keys(weekData).length){
        return FormatAmount(weekData.amount);
      }
      return FormatAmount(0);
    },
    previousPayouts(){
      const startDayOfWeek = this.user.settings.payday;
      const tiers = this.$store.getters.tiers;
      return GetPayoutsForProfile(this.profile, startDayOfWeek, tiers);
    },
    profilesByDailyLead(){
      let returnValue = [];
      this.profiles.forEach((profile) =>{
        var todayTasks = profile.entries.filter((entry) =>{
          return entry.isToday
        });
        let points = 0;
        todayTasks.forEach((entry) =>{
          points += entry.point;
        });
        returnValue.push({
          name: profile.name,
          points: points
        })
      });
      return returnValue.sort((a,b) => b.points - a.points);
    }
  },
  methods: {
    formatAmount(amount){
      return FormatAmount(amount);
    },
    requirePin(profileId){
      this.requestPin = true;
      this.requestProfileId = profileId;
    },
    setPin(pincode){
      this.pincode = pincode;
      this.requestPin = false;
      this.authProfile(this.requestProfileId);
    },
    authProfile(profileId){
      ApiRequest( {
        controller: 'PROFILE',
        action: 'authProfile',
        profileId: profileId,
        pincode: this.pincode
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.setProfile(profileId);
        } else{
          this.pincode = '';
          this.$root.errorMsg = json.statusMsg;
        }
      });
    },
    setProfile(profileId){
      ApiRequest( {
        controller: 'PROFILE',
        action: 'getProfileById',
        profileId: profileId
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$store.commit('setProfile', json);
        } else{
          console.error( json );
        }
      });
    },
    getProfileImage(profile){
      if(profile.image){
        return profile.image;
      } else{
        return GetFallbackProfileImage(profile);
      }
    }
  },
  updated() {
    window.eventManager.trigger('closeNav');
  },
  mounted(){
    const userKey = Storage.GetUserKey();
    ApiRequest({
      controller: 'USER',
      action: 'getUserProfilesById',
      userId: userKey
    }).then( response => response.json() ).then((json) =>{
      this.profiles = json;
    });
    
    if(Storage.GetProfileKey() !== null){
      this.setProfile( Storage.GetProfileKey() );
    }

    
  }
};
</script>
