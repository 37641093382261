<template>
  <div class="view create-profile">
    <section class="section">
      <div class="columns">
        <div class="column">
          
          <div class="box content">
            <div class="mb-3 ">
              <h4>Optjent</h4>
              <small class="is-flex is-text-small is-justify-content-space-between">
                Denne uge: <span>{{ getPayoutAmount('currentWeekTier')}}</span>
              </small>
              <small class="is-flex is-text-small is-justify-content-space-between">
                Sidste uge: <span>{{ getPayoutAmount('lastWeekTier') }}</span>
              </small>
              <hr class="my-1" />
              <small class="is-flex is-text-small is-justify-content-space-between">
                Saldo: <span>{{ formatAmount(profile.balance) }}</span>
              </small>
            </div>
            </div>
          <div class="box content">
            <h4>Rediger</h4>
            <EditProfileForm :pincode="pincode" />
          </div>
          <div class="box">
            <div class="content">
              <h4>Præstationer</h4>
              
              <div>
                <div class="achievement mb-1" v-for="(achievement, index) in profile.achievements" :key="`achievement-${index}`">
                  <font-awesome-icon icon="award" size="3x" :style="{color: '#F5C21B'}" />
                  <p class="ml-3">
                    <strong>{{ new Date(achievement.date).toLocaleDateString() }}</strong>
                    <br>{{ achievement.label }}
                    <br>Præmie: <strong>{{ achievement.value }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import EditProfileForm from '@/components/EditProfileForm';
import Footer from '@/components/Footer';
import { ApiRequest, FormatAmount, GetPayoutsForProfile, Storage } from '@/helpers';
export default {
  name: 'EditProfile',
  data(){
    return{
      pincode: ''
    }
  },
  components:{
    EditProfileForm,
    Footer
  },
  computed:{
    profile(){
      return this.$store.getters.profile;
    },
    user(){
      return this.$store.getters.user;
    }
  },
  methods:{
    formatAmount(amount){
      return FormatAmount(amount);
    },
    getPayoutAmount(payout){
      const startDayOfWeek = this.user.settings.payday;
      const tiers = this.$store.getters.tiers;
      const payouts = GetPayoutsForProfile(this.profile, startDayOfWeek, tiers);
      const amount = payouts[payout].amount === undefined ? 0 : payouts[payout].amount;
      return FormatAmount(amount);
    },
  },
  mounted(){
    const profileId = Storage.GetProfileKey();
    ApiRequest( {
      controller: 'PROFILE',
      action: 'getProfileById',
      profileId: profileId,
      pin: true
    } ).then( response => response.json() ).then((json) =>{
      this.pincode = json.pincode;
    });
  }
};
</script>
