<template>
  <div class="view profiles">
    <div class="card" >
      <div class="card-content">
        <h3 class="is-size-3 m-1">Profiler</h3>
        <div class="tile is-12 is-parent is-flex p-0">
          <div class="profile-image-wrapper" v-for="profile in profiles" :key="profile.id">
            <figure  class="profile-image" v-on:click="requirePin(profile.id)">
              <img class="image profile-image is-clipped is-rounded is-bordered" :src="getProfileImage(profile)" :alt="profile.name">
            </figure>
            <p class="has-text-centered">{{ profile.name }}</p>
          </div>
        </div>
        <div class="tile is-12 is-parent mt-4">
          <div class="tile box is-clickable is-child">
            <router-link :to="{ name: 'CreateProfile' }">
              <font-awesome-icon icon="user-plus"  /> Opret ny
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pincode :request-pin="requestPin" @hasPincode="setPin" />
    <Footer />
  </div>
</template>


<script>

import Pincode from '@/components/Pincode';
import Footer from '@/components/Footer';
import { ApiRequest, Status, Storage, GetFallbackProfileImage } from '@/helpers';
export default {
  name: "Overview",
  components:{
    Pincode,
    Footer
  },
  data(){
    return{
      profiles: [],
      requestPin: false,
      requestProfileId: '',
      pincode: ''
    }
  },
  computed:{
    user(){
      return this.$store.getters.user;
    },
    profile(){
      return this.$store.getters.profile;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    tasks(){
      return this.$store.getters.tasks
    }
  },
  methods: {
    requirePin(profileId){
      this.requestPin = true;
      this.requestProfileId = profileId;
    },
    setPin(pincode){
      this.pincode = pincode;
      this.requestPin = false;
      this.authProfile(this.requestProfileId);
    },
    authProfile(profileId){
      ApiRequest( {
        controller: 'PROFILE',
        action: 'authProfile',
        profileId: profileId,
        pincode: this.pincode
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.setProfile(profileId);
        } else{
          this.pincode = '';
          this.$root.errorMsg = json.statusMsg;
        }
      });
    },
    setProfile(profileId){
      ApiRequest( {
        controller: 'PROFILE',
        action: 'getProfileById',
        profileId: profileId
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$store.commit('setProfile', json);
          this.$router.push({name: 'Overview'});
        } else{
          console.error( json );
        }
      });
    },
    getProfileImage(profile){
      if(profile.image){
        return profile.image;
      } else{
        return GetFallbackProfileImage(profile);
      }
    }
  },
  mounted(){
    ApiRequest({
      controller: 'USER',
      action: 'getUserProfilesById',
      userId: Storage.GetUserKey()
    }).then( response => response.json() ).then((json) =>{
      this.profiles = json;
    });
    
    if(Storage.GetProfileKey() !== null){
      this.setProfile( Storage.GetProfileKey());
    }

    
  }
};
</script>
