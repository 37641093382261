<template>
  <div :class="`modal ${modalOpen ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card-head">
        <p class="modal-card-title">Opret opgave</p>
        <button class="delete" aria-label="close" v-on:click="cancel"></button>
      </div>
      <div class="content p-4 has-background-light mb-0">
        <form ref="createTaskForm">
          <fieldset>
            <div class="field">
              <label class="label">Label</label>
              <div class="control">
                <input class="input" type="text" placeholder="Opgave label" v-model="label" required v-on:input="isElementValid">
              </div>
            </div>
            <div class="field">
              <label class="label">Point</label>
              <div class="control">
                <input class="input" type="number" placeholder="Opgave point" v-model="point" required v-on:input="isElementValid">
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="modal-card-foot">
        <button class="button" v-on:click="cancel">Annuller</button>
        <button class="button is-success" v-on:click="save">Gem opgave</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiRequest, Status, ValidateForm, ValidateElement } from '@/helpers';

export default {
  name: 'CreateTask',
  props: [ 'modalOpen' ],
  data() {
    return {
      label: '',
      point: 0
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    cancel() {
      this.$emit( 'closeModal' );
    },
    isElementValid(event){
      ValidateElement(event.target);
    },
    isFormValid() {
      const ref = this.$refs[ 'createTaskForm' ];
      return ValidateForm( ref );
    },
    save() {
      if ( !this.isFormValid() ) {
        return false;
      }
      ApiRequest( {
        controller: 'TASK',
        action: 'addTask',
        userId: this.user.userId,
        label: this.label,
        point: this.point
      } ).then( response => response.json() ).then( ( json ) => {
        if ( json.status === Status.SUCCESS ) {
          ApiRequest( {
            controller: 'TASK',
            action: 'getTasks',
            userId: this.user.userId
          } ).then( response => response.json() ).then( ( json ) => {
            this.$store.commit( 'setTasks', json );
            this.$emit( 'closeModal' );
          } );
        }
        else {
          console.error( json );
        }
      } );
    }
  }
};
</script>