<template>
  <div class="login">
    <form ref="loginForm" v-if="!awaitingApproval" v-on:submit.prevent="logIn">
      <div v-if="controllerType === 'USER'">
        <h3 class="is-size-3 mb-2">Opret konto</h3>
        <p class="mb-2">Indtast brugernavn, email og kodeord</p>
      </div>
      <div v-if="controllerType === 'AUTH'">
        <h3 class="is-size-3 mb-2">Log ind</h3>
      </div>
      <div class="field">
        <label class="label">Brugernavn</label>
        <p class="control">
          <input type="text" class="input" name="username" v-model="username" placeholder="Brugernavn" required>
        </p>
      </div>
      <div class="field">
        <label class="label">Kodeord <p v-if="controllerType === 'USER'" class="help is-info">Minimum 8 tegn med mindst et tal og et stort bogstav</p></label>
        <p class="control">
          <input type="password" class="input" name="password" v-model="password" pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}" placeholder="Password" required>
        </p>
      </div>

      <div class="field" v-if="controllerType === 'USER'">
        <label class="label">Email <p class="help is-info">Denne email vil blive brugt til at godkende din konto</p></label>
        <p class="control">
          <input type="email" class="input" name="email" v-model="email" placeholder="Email" required>
        </p>
      </div>
      <div v-if="controllerType === 'USER'">
        <hr >
        <p class="mb-2">Indtast navn og pinkode til den første profil</p>
      </div>
      <div class="field" v-if="controllerType === 'USER'">
        <label class="label">Profil navn</label>
        <p class="control">
          <input type="text" class="input" name="profileName" v-model="profileName" placeholder="Profil navn" required>
        </p>
      </div>
      
      <div class="field" v-if="controllerType === 'USER'">
        <label class="label">Profil pinkode <p class="help is-info">Skal være en 4 tal</p></label>
        
        <p class="control">
          <input type="text" class="input" name="profilePin" minlength="4" maxlength="4" pattern="\d*" v-model="profilePin" placeholder="Profil pinkode" required>
        </p>
        
      </div>
      <div class="field">
        <p class="control is-flex is-justify-content-space-between is-align-items-center">
          <span>
            <button type="submit" class="button is-success mr-1" v-if="controllerType === 'AUTH'">
            Log ind
          </button>
          <button type="button" class="button is-info" v-on:click="setAction('createNewUser', 'USER')" v-if="controllerType === 'AUTH'">
            Opret konto
          </button>
           
            <button type="submit" class="button is-success mr-1" v-if="controllerType === 'USER'">
            Opret konto
          </button>
            <button type="button" class="button is-info" v-on:click="setAction('authenticateUser', 'AUTH')" v-if="controllerType === 'USER'">
            Annuller
           </button>
          </span>
          
        </p>
      </div>
      <hr class="mb-1" />
      <p class="is-size-7 has-text-grey is-flex is-justify-content-space-between is-align-items-center">
        <span>
          <img :src="lesionLogo" alt="https://lesion.dk" width="75">
        </span>
        <span>App version: {{ version }}</span>
      </p>
    </form>
    <div v-else>
      <div class="notification is-primary">
        <p>
          Tjek din e-mail - For at fuldføre oprettelsesprocessen er det vigtigt, at du bekræfter din e-mail-adresse.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {Status, ApiRequest} from '@/helpers';
import * as lesionLogo from '../assets/lesion.svg';

export default {
  name: "LoginForm",
  props:['version'],
  data(){
    return {
      lesionLogo: lesionLogo,
      username: '',
      password: '',
      email:'',
      profileName: '',
      profilePin:'',
      controllerType: 'AUTH',
      actionType: 'authenticateUser',
      awaitingApproval:false
    }
  },
  methods:{
    setAction(action, controller){
      this.actionType = action;
      this.controllerType = controller;
      if(controller === 'createNewUser'){
        this.username = '';
        this.password = '';
      }
      
    },
    logIn(){
      const objFetchData = {
        controller: this.controllerType,
        action: this.actionType,
        username: this.username,
        password: this.password
      };

      if(objFetchData.action === 'createNewUser'){
        objFetchData.email = this.email;
        objFetchData.name = this.profileName;
        objFetchData.pin = this.profilePin;
        objFetchData.isAdmin = 'true';
        objFetchData.image = '';
      }

      ApiRequest( objFetchData ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          if(objFetchData.action === 'createNewUser'){
            this.awaitingApproval = true;
            this.hasError = false;
          } else{
            this.$store.commit('setUser', json);
            if(this.$store.getters.user.userId){
              ApiRequest( {
                controller: 'TASK',
                action: 'getTasks',
                userId: this.$store.getters.user.userId
              } ).then( response => response.json() ).then( ( json ) => {
                this.$store.commit( 'setTasks', json );
                this.$router.push('/overview');
              } );
            }
          }


        } else {
          this.$root.errorMsg = json.statusMsg;
          this.hasError = true;
        }
      });
    }
  },
  mounted() {
    const urlParams = new URL(location.href).searchParams;
    const redirectedUsername = urlParams.get('_rdrusr');
    if(redirectedUsername){
      this.username = redirectedUsername;
    }
    
  }
};
</script>