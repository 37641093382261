import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import eventManager from './eventManager';
import { Status, ApiRequest, Storage } from '@/helpers';
import './style/index.scss';
window.eventManager = new eventManager();
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFolderPlus,
    faFolderMinus,
    faXmark,
    faSackDollar,
    faMessage,
    faKey,
    faArrowRightFromBracket,
    faUserPlus,
    faUserEdit,
    faUser,
    faUserTag,
    faMagnifyingGlass,
    faRepeat,
    faGear,
    faArrowLeft,
    faListCheck,
    faCheck,
    faUpload,
    faSave,
    faPlus,
    faUsersViewfinder,
    faStar,
    faChartColumn,
    faRotateRight,
    faNetworkWired,
    faCoins,
    faUserShield,
    faEye,
    faLock,
    faAward,
    faRightFromBracket,
    faRightToBracket
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.config.productionTip = false;

library.add( faRightFromBracket, faRightToBracket, faLock, faAward, faCheck, faChartColumn, faNetworkWired, faEye, faUserShield, faRotateRight, faCoins, faStar, faUsersViewfinder, faSave, faPlus, faUpload, faListCheck, faArrowLeft, faFolderPlus, faFolderMinus, faXmark, faSackDollar, faMessage, faKey, faArrowRightFromBracket, faUserPlus, faUserEdit, faUser, faUserTag, faMagnifyingGlass, faRepeat, faGear );

Date.prototype.getWeek = function () {
    let tdt = new Date(this.valueOf());
    let dayn = (this.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    let firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4)
    {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

const weekNumber = ( new Date() ).getWeek();

const isPWA = !!(window.matchMedia?.('(display-mode: standalone)').matches || window.navigator.standalone);

Vue.component( 'font-awesome-icon', FontAwesomeIcon );

new Vue( {
    router,
    store,
    data() {
        return {
            dataReady: false,
            appReady: false,
            weekNumber: weekNumber,
            errorMsg:'',
            isPWA: isPWA
        };
    },
    render: ( h ) => h( App ),
    created() {
        window.eventManager.listen('redirect', (options) =>{
            router.go(options.path);
        });
    },
    mounted() {
        const userKey = Storage.GetUserKey();
        if ( Storage.GetUserKey() !== null ) {
            ApiRequest( {
                controller: 'USER',
                action: 'getUserById',
                userId: userKey
            } ).then( response => response.json() ).then( ( json ) => {
                if ( json.status === Status.SUCCESS ) {
                    this.$store.commit( 'setUser', json );

                    ApiRequest( {
                        controller: 'TASK',
                        action: 'getTasks',
                        userId: userKey
                    } ).then( response => response.json() ).then( ( json ) => {
                        this.$store.commit( 'setTasks', json );
                        this.dataReady = true;
                    } );

                    this.appReady = true;
                    if ( this.$route.name !== 'Overview' ) {
                        this.$router.push( { name: 'Overview' } );
                    }
                } else{
                    alert(`Error occured - ${json.status.errorMsg}`)
                }
            } );
        }
        else {
            this.appReady = true;
            this.dataReady = true;
            if ( this.$route.path !== '/' ) {
                this.$router.push( '/' );
            }
        }
    },
    watch:{
        errorMsg(val){
            let vm = this;
            if(val !== ''){
                setTimeout(() =>{
                    vm.errorMsg = '';
                }, 5000);
            }
        }
    }
} ).$mount( '#app' );
