import Vue from "vue";
import VueRouter from "vue-router";
import Default from "../views/Default.vue";
import Overview from "../views/Overview.vue";
import Tasks from "../views/Tasks.vue";
import CreateProfile from "../views/CreateProfile.vue";
import EditProfile from '../views/EditProfile'
import Settings from "../views/Settings.vue";
import Overwatch from "../views/Overwatch";
import Profiles from '@/views/Profiles';
import Stats from '@/views/Stats';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Default",
    component: Default,
    meta: {
      requiresAuth: false,
      requireProfile: false,
      requireAdmin: false,
      buttons: []
    }
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
      requireProfile: false,
      requireAdmin: false,
      buttons: ['task']
    }
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
    meta: {
      requiresAuth: true,
      requireProfile: true,
      requireAdmin: false,
      buttons: ['return']
    }
  },
  {
    path: "/create-profile",
    name: "CreateProfile",
    component: CreateProfile,
    meta: {
      requiresAuth: true,
      requireProfile: false,
      requireAdmin: false,
      buttons: ['return']
    }
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
    meta: {
      requiresAuth: true,
      requireProfile: false,
      requireAdmin: false,
      buttons: ['return']
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      requireProfile: true,
      requireAdmin: false,
      buttons: ['return']
    }
  },
  {
    path: "/overwatch",
    name: "Overwatch",
    component: Overwatch,
    meta: {
      requiresAuth: true,
      requireProfile: true,
      requireAdmin: true,
      buttons: ['return']
    }
  },
  {
    path: "/profiles",
    name: "Profiles",
    component: Profiles,
    meta: {
      requiresAuth: true,
      requireProfile: false,
      requireAdmin: false,
      buttons: ['']
    }
  },
  {
    path: "/stats",
    name: "Stats",
    component: Stats,
    meta: {
      requiresAuth: true,
      requireProfile: true,
      requireAdmin: false,
      buttons: ['return']
    }
  }
];

const router = new VueRouter({
  routes,
});

export default router;
