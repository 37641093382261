<template>
  <article class="message is-danger mb-0" v-if="errorMsg">
    <div class="message-body">
      <strong>{{ errorMsg }}</strong>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ErrorMsg',
  props:['errorMsg']
};
</script>