<template>
  <form ref="editProfileForm">
    <div class="field">
      <label class="label">Navn</label>
      <div class="control has-icons-left">
        <input class="input" type="text" name="name" placeholder="Navn" :value="profile.name" required v-on:input="isElementValid">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="user" />
        </span>
      </div>
    </div>
    <div class="field">
      <label class="label">Pinkode</label>
      <div class="control has-icons-left has-icons-right">
        <input class="input" type="password" name="pincode" ref="pincode" placeholder="Pinkode" minlength="4" maxlength="4" pattern="\d*" :value="pincode" v-on:input="isElementValid">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="key" />
        </span>
        <span class="icon is-small is-right pincode-input-toggle" v-on:click="togglePincodeVisibility">
          <font-awesome-icon icon="eye" />
        </span>
      </div>
    </div>
    <hr />
    
    <div class="file d-flex is-align-items-center">
      <span class="image-preview mr-2" v-if="profileImage">
        <figure :class="`image is-clipped is-64x64 is-rounded`">
          <img :src="profileImage" :alt="profile.name">
        </figure>
        </span>
      <label class="file-label">
        <input class="file-input" type="file" name="profileImage" v-on:change="setImage">
        <span class="file-cta">
      <span class="file-icon">
        <font-awesome-icon icon="upload"  />
      </span>
      <span class="file-label" v-if="profileImage">
        Skift billede
      </span>
          <span class="file-label" v-else>
        Vælg billede
      </span>
          
    </span>
      </label>
      
    </div>
    <hr />
    <div class="field" v-if="profile.isAdmin">
      <input id="isAdminInput" type="checkbox" class="switch" name="isAdmin" v-model="profile.isAdmin" v-on:input="isElementValid">
      <label for="isAdminInput">Forældre profil</label>
    </div>
    <hr />
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-success" v-on:click.prevent="saveProfile">Gem profil</button>
      </div>
      <div class="control">
        <router-link class="button is-light" :to="{ name: 'Overview'}">
          Annuller
        </router-link>
      </div>
    </div>
    <ImageCropper v-if="uploadedImage" :src="uploadedImage" @imageCropped="imageCropped" />
  </form>
</template>

<script>
import { ApiUrl, Status, ApiRequest, ValidateElement, ValidateForm, GetImageDataFromUri } from '@/helpers';
import ImageCropper from "./Cropper.vue"
export default {
  name: 'EditProfileForm',
  components:{ImageCropper},
  props:['pincode'],
  data(){
    return{
      editedProfile: {
        name:'',
        pincode:'',
        image:'',
        isAdmin: null
      },
      previewImage:'',
      uploadedImage: '',
      imageThumb:''
    }
  },
  computed:{
    profile(){
      return this.$store.getters.profile;
    },
    profileImage(){
      if(this.previewImage){
        return this.previewImage;
      }
      let image = this.profile.image || '';
      if(image === ''){
        image = this.editedProfile.image
      }
      return image;
    }
  },
  methods:{
    togglePincodeVisibility(){
      let pincodeInput = this.$refs.pincode;
      if(pincodeInput.type === 'password'){
        pincodeInput.type = 'text';
      } else{
        pincodeInput.type = 'password';
      }
    },
    imageCropped(image){
      this.uploadedImage = '';
      this.previewImage = image;
      this.editedProfile.image = image;
    },
    isElementValid(event){
      this.editedProfile[event.target.name] = event.target.value;
      ValidateElement(event.target);
    },
    isFormValid(){
      const ref = this.$refs['editProfileForm'];
      return ValidateForm(ref);
    },
    setImage(event){
      const image = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (stream) =>{
        this.uploadedImage = stream.target.result;
      };
    },
    saveProfile(){
      if(!this.isFormValid()){
        return false;
      }
      let requestsArray = [];
      
      
      const objFetchData = {
        controller: 'PROFILE',
        action: 'updateProfile',
        name: this.editedProfile.name.length ? this.editedProfile.name : this.profile.name,
        profileId: this.$store.getters.profile.id,
        isAdmin: this.editedProfile.isAdmin ? this.editedProfile.isAdmin.toString() : this.profile.isAdmin.toString(),
        pincode: this.editedProfile.pincode.length ? this.editedProfile.pincode : this.pincode
      };
      
      if(this.editedProfile.image){
        const formUploadData = new FormData();
        formUploadData.append('file', GetImageDataFromUri(this.editedProfile.image));
        formUploadData.append('profileId', this.$store.getters.profile.id);

        const fetchUploadOptions = {
          method: 'POST',
          body: formUploadData
        };
        
        const uploadImageRequest = fetch(`${ApiUrl}Services/UploadService/`, fetchUploadOptions);
        requestsArray.push(uploadImageRequest);
      }
      const saveProfileRequest = ApiRequest(objFetchData);
      requestsArray.push(saveProfileRequest);

      Promise.all( requestsArray.map( ( request ) => {
        return request.then( ( response ) => {
          return response.json();
        } ).then( ( data ) => {
          return data;
        } );
      } ) ).then( ( values ) => {
        if ( values.some( v => v.status !== Status.SUCCESS ) ) {
          alert( 'error' );
        }
        else {
          let imageResponse = values.find(obj => {
            return obj.file !== undefined;
          }) || null;
          let profileResponse = values.find(obj => {
            return obj.response !== undefined;
          }) || null;
          
          if(imageResponse !== null && imageResponse.response){
            profileResponse.response.image = imageResponse.file;
          }
          this.$store.commit('setProfile', profileResponse.response);
          this.$router.push('/');
        }
      } );
      
    }
  }
};
</script>

<style scoped>
.pincode-input-toggle{
  cursor: pointer!important;
  pointer-events: all!important;
}
</style>